<template>
  <div class="row kozima-container row position-relative m-0 h-100" v-if="getKozimaTimerSec>5">
    <div class="col-12 mt-4" >
      <div class="">
        
        <!-- <div class="d-flex justify-content-end m-2">
          <button class="kozima-btn px-3 py-2">CONNECT WALLET</button>
        </div> -->
        <div class="">
          <div class="d-flex justify-content-center breathe">
            <div id="kozima-title" class="kozima-title">KOZMIK BUMZ</div>
          </div>
          <div id="kozima-title-2" class="kozima-title-2 breathe">JEFF’S GROTTO</div>
        </div>
      </div>
      <div style="margin-top: 30px">
        <div class="kozima-images">
          <LazyImage
            class="position-absolute breathe2 head"
            style="z-index: 223; width: 400px; top: -132px"
            src="https://cdn.shopify.com/s/files/1/0561/3780/9974/files/kozmik2.png?v=1724741560"
          ></LazyImage>
        </div>
        <div class="kozima-images">
          <LazyImage
            class="position-absolute breathe2 leaves"
            style="width: 700px; top: -67px"
            src="https://cdn.shopify.com/s/files/1/0561/3780/9974/files/kozmik3.png?v=1724741560"
          ></LazyImage>
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <div class="kozima-card  p-3  pt-4 p-md-5" >
            <div class="d-flex justify-content-between">
              <div class="player-info">
                <div class="player-name">Progress</div>
                <div class="player-score score-seconds" >
                   {{getKozimicProgress.current+'/'+getKozimicProgress.total}}
              
                </div>
              </div>
              <div class="player-info">
                <div class="player-name">Time Left</div>
                <div class="player-score score-seconds" >
                  :{{ getKozimaTimerSec-5 }}s
                </div>
              </div>
            </div>
            <div class="round-info">
              {{ getHeading[0].content }}
            </div>
            <div class="d-flex justify-content-center flex-column align-items-center mt-3 mt-md-5">
              <div class="question-info">
                {{ getHeading[1].content }}
              </div>
              <!--  -->
              
              <div class="my-2 is-correct-answer" :style="{color:getOptions[isNextPageLoading].score_value?'#05ec00':'#dc3545'}" v-if="isNextPageLoading!=null">
             {{ getOptions[isNextPageLoading].score_value?'Correct':'Incorrect' }}  
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-center pb-5">
              <div class="Kozima-options row" style="width: 605px">
                <div
                  class="col-12 col-md-6 mt-3"
                  @click="updateSelectedAnswer(option, index)"
                  v-for="(option, index) in getOptions"
                  :key="option.id"
                >
                  <div
                    class="kozima-option"
                    :class="[
                      isNextPageLoading == index ? 'selected' : '',
                      isNextPageLoading != null ? 'pointer-none' : '',
                    ]"
                  >
                    {{ index + 1 }}. {{ option.value }}
                  </div>
                </div>
              </div>
            </div>
            <div class="position-relative">
              <LazyImage
                class="position-absolute breathe2 float-toon"
                style="width: 256px; left: -110px; top: -64px"
                src="https://cdn.shopify.com/s/files/1/0561/3780/9974/files/kosmik4.png?v=1724741560"
              ></LazyImage>
            </div>
            <div class="position-relative">
              <LazyImage
                class="position-absolute breathe2 float-toon"
                style="width: 256px; right: -110px; top: -25px"
                src="https://cdn.shopify.com/s/files/1/0561/3780/9974/files/kozmik5.png?v=1724741559"
              ></LazyImage>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </div>
  <div class="col-12" style="background-color: black; height: 100dvh;" v-else>
<div class="container position-relative">
  <div class=" mt-5">
    <LazyImage style="width: 80%;"
    class=" OutOfRunText mt-5 breathe2"
            src="https://images.quizell.com/gallery/7601726821401.png"
          ></LazyImage>
  </div>
  <div class=" ">
    <LazyImage
    class="unicorn breathe2"
            src="https://images.quizell.com/gallery/791725707556.png"
          ></LazyImage>
  </div>
</div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import axios from "axios";
import { getFormatedUTCTimeStamp } from "../../../composibles/getUTCTimeStamp";
export default {
  props: [
    "isTest",
    "isDesktopView",
    "animationType",
    "RoutePage",
    "getIsAnimationEnabled",
    "getAnimationSpeedinMillis",
    "apiToken",
  ],
  created() {
    this.pageDesign = this.getSinglePageValues();
  },
  data() {
    return {
      pageDesign: {},
      isNextPageLoading: null,
    };
  },
  mounted() {
    this.kozimaCheckTimer(this.getSelectedPage.id);
  },
  computed: {
    ...mapGetters([
      "getIsLoadingQuestion",
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getTotalPreviewPages",
      "getPreviewSettings",
      "getPreviewProgressBar",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
      "getFurnitureClientCounterData",
      "getTrackIkeaClientCounter",
      "getEnvConst",
      "getKozimaTimerSec",
      "getKozimCurrectSectionIndex"
    ]),
    getKozimicProgress(){
      let progress={
        total:0,
        current:0
      }
      if(this.getEnvConst?.KOZIMA_GAME)
    {
      let questions = JSON.parse(this.getEnvConst?.KOZIMA_GAME)[this.getKozimCurrectSectionIndex].questions
      progress.total=questions.length;
      progress.current= questions.findIndex(question=>question==this.getSelectedPage.id)+1
    }
    return progress
    },
    getHeading() {
      return this.getSelectedPage.pageDesign.blocksArray.filter(
        (block) => block.type == "text"
      );
    },
    getOptions() {
      return this.pageDesign.blocksArray[this.findOptionsIndex].options;
    },
    findOptionsIndex() {
      const index = this.pageDesign.blocksArray.findIndex(
        (block) => block.type == "option"
      );
      return index;
    },
    isLoadingQuestion() {
      return this.getIsLoadingQuestion;
    },
    CurrentPage() {
      return this.getSelectedPage;
    },
  },
  methods: {
    ...mapActions([
      "goToNextPreviewPage",
      "goToBackPreviewPage",
      "updateSelectedOptionsObject",
      "createEndQuizPage",
      "kozimaCheckTimer",
    ]),
    ...mapMutations([
      "jumpToPreviewPage",
      "PreviewPagesStack",
      "backPageAnimation",
      "setIsLoadingQuestion",
    ]),
    getSinglePageValues() {
      let getStartedDesignValues = {};
      if (this.getSelectedPage) {
        getStartedDesignValues = this.getSelectedPage.pageDesign;
      }
      return getStartedDesignValues;
    },
     playClickSound() {
    // Create a new Audio context
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    // Create an audio buffer source node
    const source = audioContext.createBufferSource();

    // Create a gain node for volume control
    const gainNode = audioContext.createGain();
    gainNode.gain.value = 2.0; // Set volume to 200%

    // Fetch the audio file
    fetch('https://quizell.nyc3.cdn.digitaloceanspaces.com/audios/button-click-sound.mp3')
        .then(response => response.arrayBuffer())
        .then(data => audioContext.decodeAudioData(data))
        .then(buffer => {
            source.buffer = buffer;
            source.connect(gainNode);
            gainNode.connect(audioContext.destination);
            source.start();
        })
        .catch(error => {
            console.error('Error playing audio:', error);
        });
},
    async updateSelectedAnswer(value, selectedOptionIndex) {
      if (!value) return;
    
      if (this.isLoadingQuestion) return;
      const contentIndex = this.findOptionsIndex;
      this.playClickSound()
      await this.processSelectedOption(
        value,
        contentIndex,
        selectedOptionIndex
      );
    },
    async IncrementQuestionAnalyticsCount(
      selectedOptionObj,
      mode,
      selectedOptionIndex
    ) {
      if (this.isTest) {
        if (mode == "jumpLogic") return true;

        this.goToNextPreviewPage(this.getSelectedPage.value);
        return false;
      }

      let { quiz_analytic_id } = this.getSavedLeadID;

      if (quiz_analytic_id) {
        try {
          this.isNextPageLoading = selectedOptionIndex;
          this.setIsLoadingQuestion(true);
          let quizAnalyticsPayload = {
            api_token: this.apiToken || this.$route.params.id,
            question_id: this.CurrentPage.id,
            quiz_analytic_id: quiz_analytic_id,
            option_ids:
              selectedOptionObj.selectedOption &&
              "id" in selectedOptionObj.selectedOption
                ? [selectedOptionObj.selectedOption.id]
                : [],
          };

          const response = await axios.post(
            `/api/question/analytic/add`,
            quizAnalyticsPayload
          );

          if (response.status == 200) {
            if (mode == "jumpLogic") {
              return true;
            }

            this.goToNextPreviewPage(this.getSelectedPage.value);
            return false;
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.setIsLoadingQuestion(false);
          this.isNextPageLoading = null;
        }
      } else {
        try {
          this.isNextPageLoading = true;
          let data = {
            api_token: this.apiToken || this.$route.params.id,
            quizId: this.getPreviewSettings.quizId,
            value_type: "Started",
            started_at: getFormatedUTCTimeStamp(),
          };

          const response = await axios.post(`/api/updateQuizAnalytic`, data);
          if (response.status == 200) {
            let { quiz_analytic_id } = response.data.data;
            if (quiz_analytic_id) {
              await this.$store.commit(
                "assignQuizAnaylticsId",
                quiz_analytic_id
              );
              this.IncrementQuestionAnalyticsCount(
                selectedOptionObj,
                mode,
                selectedOptionIndex
              );
            }
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
          }
        } finally {
          this.isNextPageLoading = false;
        }

        // Recursive call
      }
    },

    async processSelectedOption(value, contentIndex, selectedOptionIndex) {
      try {
        const selectedOptionObj = this.createSelectedOptionObj(value);
        this.pageDesign.blocksArray[contentIndex].selectedOption = value;
        await this.updateSelectedOptionsObject(selectedOptionObj);

        this.IncrementQuestionAnalyticsCount(
          selectedOptionObj,
          "normal",
          selectedOptionIndex
        );
      } catch (error) {
        if (error) throw error;
      }
    },
    createSelectedOptionObj(value) {
      const obj = new Object();
      obj.questionId = this.getSelectedPage.id;
      obj.isMasterQuestion = this.getSelectedPage.isMasterQuestion;
      obj.questionType = this.getSelectedPage.value;
      obj.selectedOption = value;
      return obj;
    },
  },
};
</script>

<style>
@font-face {
  font-family: "Billy";
  src: url("https://quizell.nyc3.cdn.digitaloceanspaces.com/fonts/Billy-Regular.woff2")
    format("woff2");
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

/* Bold weight */
@font-face {
  font-family: "Billy";
  src: url("https://quizell.nyc3.cdn.digitaloceanspaces.com/fonts/Billy-Bold.woff2")
    format("woff2");
  font-weight: 700; /* Bold weight */
  font-style: normal;
}

/* Light weight */
@font-face {
  font-family: "Billy";
  src: url("https://quizell.nyc3.cdn.digitaloceanspaces.com/fonts/Billy-Light.woff2")
    format("woff2");
  font-weight: 300; /* Light weight */
  font-style: normal;
}
/* Blough */
@font-face {
  font-family: "Blogh";
  src: url("https://quizell.nyc3.cdn.digitaloceanspaces.com/fonts/Blogh.woff2")
    format("woff2");
  font-weight: 400; /* Light weight */
  font-style: normal;
}
@font-face {
  font-family: "Bob";
  src: url("https://quizell.nyc3.cdn.digitaloceanspaces.com/fonts/bob-regular.woff2")
    format("woff2");
  font-weight: 400; /* Regular weight */
  font-style: normal;
}

.kozima-images {
  position: relative;
  display: flex;
  justify-content: center;
}
.kozima-card {
  width: 938.69px;
  min-height: 674.421px;
  gap: 0px;
  border-radius: 52px;
  background: black;
  z-index: 222;
  padding: inline-block;
  position: sticky;
  margin-top: 185px;
  margin-bottom: 185px;
  position: relative;
}
.kozima-container {
  min-height: 100dvh;
  background: url("https://cdn.shopify.com/s/files/1/0561/3780/9974/files/kozmik1.png?v=1724741563")
    no-repeat center center fixed;
  background-size: cover;
  /* flex:1; */
  min-width: 100dvw;
}
.kozima-btn {
  border-radius: 10px;
  background: #ff2df1;
  font-family: Billy;
  font-size: 21.81px;
  font-weight: 400;
  line-height: 15.26px;
  letter-spacing: -0.08em;
  text-align: center;
  color: white;
}
.kozima-title {
  font-family: Blogh, sans-serif; /* Fallback to a generic font if Blogh is not available */
  font-size: 65.92px;
  font-weight: 400;
  line-height: 46.14px;
  text-align: center;
  background: linear-gradient(
    349deg,
    #de00f8 3.27%,
    #00b2fe 36.75%,
    #fff600 68.81%,
    #ff097c 95.12%
  );
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.kozima-title-2 {
  font-family: Bob;
  font-size: 130px;
  font-weight: 400;
  line-height: 88.28px;
  /* letter-spacing: -0.11em; */
  text-align: center;
  color: #ffff00;
}
.player-info .player-name {
  font-family: Billy;
  font-size: 42.25px;
  font-weight: 400;
  line-height: 29.58px;
  letter-spacing: -0.08em;

  color: #ff28d5;
}

.round-info {
  color: #ff28d5;
  text-align: center;
  font-family: Billy;
  font-size: 69.292px;
  font-style: normal;
  font-weight: 400;
  line-height: 70%; /* 48.504px */
  letter-spacing: -5.543px;
}
.question-info {
  color: #ff0;
  text-align: center;
  font-family: Billy;
  font-size: 42.253px;
  font-style: normal;
  font-weight: 400;
  line-height: 89%; /* 37.605px */
  letter-spacing: -3.38px;
  max-width: 715px;
}
.breathe {
  animation: breathe 3s infinite ease-in-out;
}
.breathe2 {
  animation: breathe2 4s infinite ease-in-out;
}
.kozima-option {
  border-radius: 17px;
  background: #ff24d5;
  padding: 20px;
  color: #ff0;
  font-family: Billy;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 89%; /* 21.36px */
  letter-spacing: -1.92px;
  cursor: pointer;
}
.kozima-option:hover {
  background: #ff19c4; /* Slightly darker shade for hover effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.kozima-option:active {
  transform: perspective(500px) rotateX(10deg) rotateY(10deg);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.pointer-none {
  pointer-events: none;
}
.kozima-option.selected {
  animation: animate3D 0.3s infinite ease forwards;
  background: #ff19c4; /* Slightly darker shade for hover effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}
@keyframes breathe2 {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes breathe {
  0% {
    transform: translateY(0);
    opacity: 0.8;
  }
  50% {
    transform: translateY(-20px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.8;
  }
}
@keyframes animate3D {
  0% {
    transform: perspective(500px) rotateX(0deg) rotateY(0deg);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
  50% {
    transform: perspective(500px) rotateX(10deg) rotateY(10deg);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  100% {
    transform: perspective(500px) rotateX(0deg) rotateY(0deg);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }
}

@media only screen and (max-width: 550px) {
  #kozima-title-2 {
    font-size: 56px!important;
    line-height: 46.28px!important;
  }
  .round-info {
    margin-top: 26px;
}
.kozima-card{
    margin-top: 15px!important;
    width: 100%!important;
  }
  .question-info{
    font-size:18px;
    letter-spacing:0px;
  }
  .is-correct-answer{
    font-size:18px!important;
  }
  .round-info {
  font-size:48px;
  }
  .kozima-option{
    padding:12px;
    font-size:20px;
    letter-spacing: 0px;
    border-radius: 3px;
    text-align: center;
  }
  .score-seconds {
        font-size: 38px !important;
        line-height: 40px !important;
    }
    .player-info .player-name {
        font-size: 27.25px !important;
    }
}
.is-correct-answer{
                text-align: center;
    font-family: Billy;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 89%;
              }
              .player-info .player-score {
  color: #05ec00;
  text-align: center;
  font-family: Billy;
  font-style: normal;
  font-weight: 400;
  line-height: 90px; /* 29.577px */
  letter-spacing: -3.38px;
}
@media only screen and (min-width: 768px)    {
  .player-info .player-score {
  font-size: 78px;

}
.score-seconds{
  font-size: 78px
}
              }
@media only screen and (max-width: 768px) {
  .unicorn{
    position: static!important;
    width: 100%!important;
  }
  #kozima-title {
    font-size: 50px;
  }
  #kozima-title-2 {
    font-size: 80px;
    line-height: 46.28px;
  }
  .leaves{
    display: none;
    width: 443px!important;
  }
  .head{
    display: none;
    width: 280px!important;
    top:-80px!important;
  }
  .kozima-card{
    margin-top: 15px!important;
    width: 90%;
  }
  .float-toon{
    display: none;
  }
  .player-info .player-name {
  font-size: 32.25px;
}
.player-info .score-seconds{
  font-size: 48px;
}
.OutOfRunText{
  width: 100%!important;
}
}
.unicorn{
  width: 50%; 
    width: 50%;
    position: absolute;
    right: 70px;
    top: 28px;
}
</style>
